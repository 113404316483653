/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap"); */

.loader-display {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Poppins", sans-serif !important;
  font-weight: 700 !important;
  width: 50%;
}

.loader-display > h2 {
  font-size: 0.875rem !important;
  line-height: 1.25rem;
  letter-spacing: 0.0178571429em !important;
  font-family: "Poppins", sans-serif !important;
  margin-top: -25px !important;
  margin-bottom: 40px !important;
  font-weight: 700 !important;
}

.dots-loader-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px !important;
}
.dot-flashing {
  position: relative;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background-color: #989898;
  animation: dot-flashing 0.7s infinite alternate;
  animation-delay: 0.3s;
}
.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -25px;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background-color: #989898;
  animation: dot-flashing 0.7s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 25px;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background-color: #989898;
  animation: dot-flashing 0.7s infinite alternate;
  animation-delay: 0.6s;
}
@keyframes dot-flashing {
  0% {
    background-color: #989898;
  }
  50%,
  100% {
    background-color: #d7d7d7;
  }
}

.divider {
  --background: #f9f9f9;
  height: 1px;
}

#content-setting-wrapper > ion-list > ion-item::part(native) {
  padding: 0 !important;
}

.card-setting > .card-content-ios {
  padding: 0;
  padding-left: unset;
  padding-right: unset;
  -webkit-padding-start: 0px;
  padding-inline-start: 0px;
  -webkit-padding-end: 0px;
  padding-inline-end: 0px;
}

@media screen and (min-width: 1024px) {
  .setting-list-desk > ion-item {
    --inner-padding-top: 0;
  }
}

ion-modal#modal-confirmation {
  --width: fit-content;
  --max-width: 310px;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

/* SIGNTYPE2 */
.types-sign > p {
  font-weight: lighter;
  font-size: 12px;
  /* font-size: 30px !important; */
}

ion-modal#modal-confirmation ion-icon {
  margin-right: 6px;
  width: 48px;
  height: 48px;
  padding: 4px 0;
  color: #aaaaaa;
}

.modal-confirmation-header {
  --height: 35px;
  text-align: center;
  padding: 0px 20px;
  margin: 0 auto;
  line-height: 19px;
  color: #0a0a0a;
  font-size: 16px;
}
.modal-confirmation-header > ion-label {
  display: flex;
  justify-content: space-between;
}

.modal-confirmation-button-wrapper {
  --background-focused: none;
}
.item {
  --inner-padding-end: 0 !important;
}
.modal-confirmation-button-wrapper > ion-button {
  display: flex;
  justify-content: space-between;
  font-weight: bolder;
  letter-spacing: 1px;
  height: 36px;
  width: 100%;
}

.modal-confirmation-btn-reject {
  --background: #e4e4e4;
  --color: #214999;
}

.modal-confirmation-token-section-wrapper {
  padding: 16px 20px;
  --min-height: 10px;
}

.modal-confirmation-body-step3 {
  display: flex;
  text-align: center;
  justify-content: space-around;
}
.modal-confirmation-body-arrow {
  display: flex;
  align-items: center;
}
.modal-confirmation-body-step3-img-wrapper {
  margin: 0 auto;
  border: 2px solid #000000;
  border-radius: 100px;
}
.modal-confirmation-body-step3-token {
  max-width: 106px;
  font-weight: bold;
}

.button-reject-session-proposal {
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #214999;
  --background: #e4e4e4;
}

#legacySessionProposalModalGrid {
  --padding-start: 10px;
}

.permission-list .item {
  padding: 0 16px;
  --detail-icon-opacity: 0;
}

.permission-form-list .item {
  --background: #f9f9f9;
}

.permission-form-list-desk .item {
  --background: inherit;
}

ion-input.permission {
  --background: #e4e4e4;
  --color: #5d5d5d;
  --placeholder-color: #5d5d5d;
  --placeholder-opacity: 0.8;
  margin: 10px 0;
  --padding-bottom: 10px;
  --padding-end: 10px;
  --padding-start: 10px !important;
  --padding-top: 10px;
  border-radius: 8px;
}

ion-checkbox {
  --size: 26px;
  --background-checked: #214999;
  margin: 0;
}

ion-checkbox::part(container) {
  border-radius: 6px;
  border: 2px solid #214999;
}

.format-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  min-width: 100%;
}

.format-label .format-label-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: row;
  margin-top: 2px;
}

.format-label .format-label-start ion-label {
  font-size: 16px;
  font-weight: bold;
  display: block;
  align-self: center;
  margin-bottom: 0;
}

.required-field {
  background: #e4e4e4;
  padding: 0px 6px;
  margin: 0 6px;
  border-radius: 20px;
  /* min-width: 69px; */
  font-size: 14px;
  font-weight: bold;
  color: #214999;
}

.button-containers {
  position: fixed;
  width: 100%;
  bottom: 0;
  display: flex;
  padding: 10px 16px;
  flex-direction: column;
  justify-content: space-between;
  margin-left: -16px;
}

ion-alert.delete-account {
  --backdrop-opacity: 0.7;
}

ion-alert.delete-account {
  --backdrop-opacity: 0.7;
}

.delete-account .alert-button-group {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: initial;
  row-gap: 10px;
  margin-top: -25px;
}

button.alert-button.alert-button-confirm {
  background-color: var(--ion-color-danger);
  color: white;
  border: 0;
  border-radius: 24px;
  font-weight: 500;
  width: 126px;
}

button.alert-button.alert-button-confirm.bg-green {
  background-color: var(--ion-color-primary) !important;
}

.md button.alert-button.alert-button-confirm {
  border-radius: 4px;
}

.ios .delete-account button.alert-button {
  border: 0;
}

.ios button.alert-button.alert-button-cancel {
  border-radius: 24px;
  background: #e4e4e4;
  color: #214999;
  border: 0;
  width: 126px;
  font-weight: 500;
  margin-right: 5px;
}

.ios button.alert-button.alert-button-confirm {
  border-radius: 24px;
}

@media screen and (min-width: 1024px) {
  .button-containers {
    position: relative;
    width: 100%;
    margin-left: 0;
  }
}

.ion-item-split {
  /* padding-right: 12px; */
  background: #f9f9f9 !important;
}

/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/

@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

.font-inter {
  font-family: "Inter", sans-serif !important;
}

.tabBottom {
  height: 68px;
  background: white;
  border: 0;
}

.bold {
  font-weight: 700;
  text-transform: uppercase;
}

/* ion-router-outlet {
  width: 100%;
  display: flex;
  justify-content: center;
  background: none !important;
} */

/* @media only screen and (min-width: 4px) {
  ion-app {
    width: 100% !important;
    margin: 0 auto;
  }
} */

/* .ion-page {
  max-width: 375px !important;
  margin: 0 auto;
  min-width: 100vw;
  border-right: 0.2px solid lightgray;
  border-left: 0.2px solid lightgray;
} */

/* ion-tabs {
  max-width: 375px;
  margin: 0 auto;
} */

ion-modal > #ion-react-wrapper {
  max-width: 100%;
}

:root {
  /** primary **/
  --ion-font-family: "Roboto", sans-serif;
  --ion-color-primary: #214999;
  --ion-color-primary-rgb: 33, 73, 153;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #1d4087;
  --ion-color-primary-tint: #375ba3;

  /** secondary **/
  --ion-color-secondary: #e4e4e4;
  --ion-color-secondary-rgb: 228, 228, 228;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #c9c9c9;
  --ion-color-secondary-tint: #e7e7e7;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #0a9a2a;
  --ion-color-success-rgb: 10, 154, 42;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #098825;
  --ion-color-success-tint: #23a43f;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #3d3d3d;
  --ion-color-dark-rgb: 61, 61, 61;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #363636;
  --ion-color-dark-tint: #505050;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #fff;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-tab-bar-background: white;
  --ion-toolbar-background: white;
  --ion-color-light-blue: #5f8bff;
  --ion-color-light-blue-rgb: 95, 139, 255;
  --ion-color-light-blue-contrast: #ffffff;
  --ion-color-light-blue-contrast-rgb: 0, 0, 0;
  --ion-color-light-blue-shade: #547ae0;
  --ion-color-light-blue-tint: #6f97ff;

  --ion-color-light-two: #e4e4e4;
  --ion-color-light-two-rgb: 228, 228, 228;
  --ion-color-light-two-contrast: #214999;
  --ion-color-light-two-contrast-rgb: 0, 0, 0;
  --ion-color-light-two-shade: #c9c9c9;
  --ion-color-light-two-tint: #e7e7e7;
}

.ion-color-light-blue {
  --ion-color-base: var(--ion-color-light-blue);
  --ion-color-base-rgb: var(--ion-color-light-blue-rgb);
  --ion-color-contrast: var(--ion-color-light-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-blue-shade);
  --ion-color-tint: var(--ion-color-light-blue-tint);
}

.title-page {
  font-weight: bold;
}

.gray-bg {
  background: var(--ion-color-secondary-shade);
}

ion-modal#center-modal {
  --height: fit-content;
  --backdrop-opacity: 0.5;
  --min-height: 30%;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

ion-modal#center-modal::part(backdrop) {
  /* background: rgba(209, 213, 219); */
  opacity: 1;
}

.gapIcon {
  margin-bottom: 7px;
}

.split-panel-menu {
  --side-min-width: 90px;
  width: 90px;
  box-shadow: 10px 10px 32px 0px rgba(194, 183, 183, 0.75);
  -webkit-box-shadow: 10px 10px 32px 0px rgba(194, 183, 183, 0.75);
  -moz-box-shadow: 10px 10px 32px 0px rgba(194, 183, 183, 0.75);
}

.menus-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 37px 0;
  color: #214999 !important;
  font-weight: bold;
  cursor: pointer;
}

.purchase-accor > ion-item::part(native) {
  padding: 0;
}

.input-amount > .input-item::part(native) {
  padding: 0;
}

.menus-item-disabled {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 37px 0;
  color: #bababa !important;
  font-weight: bold;
  cursor: pointer;
}

.icon-split {
  font-size: 18px;
}

.no-padding {
  padding: 0 !important;
}

.modal-purchase {
  --max-width: 414px;
  padding: 1%;
}

.bg-base {
  --background: #f2f2f2;
}

@media screen and (min-width: 1024px) {
  .ion-app {
    --background: #f2f2f2 !important;
  }

  .content-mobile {
    --background: #f2f2f2;
  }

  .buttons-home {
    padding: 1rem;
    justify-content: flex-end;
    margin: 0;
  }

  .header-collapse-condense ion-toolbar {
    --ion-toolbar-background: #f2f2f2;
    --background: #f2f2f2;
  }

  .modal-purchase {
    --max-height: 640px;
  }

  .backup-modal {
    --max-width: 375px;
    --max-height: 300px;
    padding: 1rem;
  }
}

.font-12 {
  font-size: 12px;
}

.weight-700 {
  font-weight: 700;
}

.flex-centered {
  display: flex;
  overflow-y: auto;
  padding: 0 20px 20px 20px;
  height: 100%;
}

.pointer {
  cursor: pointer;
}

[aria-checked="true"].sc-ion-alert-ios .alert-radio-label.sc-ion-alert-ios {
  color: #000000;
  font-weight: bold;
}

.gray {
  --background: #f9f9f9;
}

.scroll-y {
  overflow-y: auto;
}

ion-icon.ion-accordion-toggle-icon {
  margin-inline: 0px !important;
  font-size: 20px;
}

/* .mt-10 {
  margin-top: 10px;
} */

/* .h-48 {
  height: 48px;
} */

.mh-50 {
  max-height: 50%;
}

/* .ml-20 {
  margin-left: 20px !important;
} */

.mt-100 {
  margin-top: 100px;
}

/* .ml-10 {
  margin-left: 10px !important;
} */
.mw-50-vh {
  max-width: 50vh;
}

.mh-80vh {
  max-height: 80vh;
}

.flexRowStart {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

/* .m-0 {
  margin: 0;
} */

/* .m-20 {
  margin: 20px;
} */

.flexColStart {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.flexColBetween {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

@media screen and (min-width: 1440px) {
  .mh-50 {
    max-height: 70%;
  }
}

/** ----------------------------------------- */
/** New Adjustment for Multiple CSS Component */
/** ----------------------------------------- */

.notifications {
  width: 36px;
  --border-radius: 50%;
  --padding-end: 10px;
  --padding-start: 10px;
}

.wc {
  --border-radius: 50%;
  --padding-end: 5px;
  --padding-start: 5px;
  padding-inline-start: 0;
  padding-inline-end: 0;
  border-radius: 50%;
  height: 36px;
  width: 36px;
}

ion-item::part(native) {
  padding: 10px 2px;
  margin: 0px;
}

.tooltip {
  display: inline-block;
  position: relative;
  text-align: left;
}

.tooltip .bottom {
  min-width: 128px;
  max-width: 128px;
  height: auto;
  top: 5px;
  left: 55%;
  transform: translate(-50%, 0);
  color: #ffffff;
  padding: 8px;
  background-color: #5d5d5d;
  font-weight: normal;
  font-size: 12px;
  border-radius: 10px;
  position: absolute;
  font-family: "Roboto";
  z-index: 99999999;
  box-sizing: border-box;
  border: 1px solid #5d5d5d;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  visibility: visible;
  opacity: 1;
  transition: opacity 0.8s;
}

.tooltip:hover .bottom {
  visibility: visible;
  opacity: 1;
}

.tooltip .bottom i {
  position: absolute;
  bottom: 100%;
  right: 5%;
  margin-left: -12px;
  width: 24px;
  height: 12px;
  overflow: hidden;
}

.tooltip .bottom i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  right: 2%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: #5d5d5d;
  border: 1px solid #5d5d5d;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

ion-button.save {
  width: 100%;
  --background: #214999;
  --color: white;
  min-height: 40px;
  margin-top: 5%;
}

.my-custom-class {
  --background: white;
  --spinner-color: #214999;
}

.modal-transfer-done {
  /* max-width: 375px; */
  margin: 0px auto;
}

.modal-contact-bar {
  --max-width: 100%;
  --height: fit-content;
  --border-radius: 20px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

.modal-gas-confirm > #ion-react-wrapper {
  --background: rgba(0, 0, 0, 0.75);
  --box-shadow: none !important;
  --border-color: #808080 !important;
  outline: none !important;
  --border-radius: 0px !important;
}

.modal-gas-confirm-wrapper {
  background-color: white;
  border-radius: 30px;
}

ion-content::part(scroll) {
  overflow: auto;
}

ion-modal#modal-error {
  --width: 95%;
  --max-width: 375px;
  --min-width: 250px;
  --height: fit-content;
  --backdrop-opacity: 0.5;
}

ion-modal#modal-error ion-icon {
  width: 32px;
  height: 32px;
  padding: 4px 0;
  color: #0a0a0a;
}

ion-modal#modal-error-c,
ion-modal#modal-success {
  --width: 90%;
  --max-width: 200px;
  --min-width: 100px;
  --height: fit-content;
  --backdrop-opacity: 0.5;
}

ion-modal#modal-error-c ion-icon,
ion-modal#modal-success ion-icon {
  width: 32px;
  height: 32px;
  padding: 4px 0;
  color: #0a0a0a;
}

#modal-error::part(content) {
  border-radius: 10px;
}

#modal-error-c::part(content) {
  border-radius: 10px;
}

#modal-success::part(content) {
  border-radius: 10px;
}

.item-list {
  --padding-end: 0px;
  --padding-start: 0px;
}

.no-padding-no::part(native) {
  padding: 0px !important;
}

ion-button.inactive::part(native) {
  color: #214999;
  font-weight: bolder;
  background: #e4e4e4;
}

ion-button.active::part(native) {
  font-weight: bolder;
}

ion-modal#modal-gas-example {
  --width: 95%;
  --max-width: 375px;
  --min-width: 250px;
  --height: fit-content;
  --backdrop-opacity: 0.5;
}

#modal-gas-example::part(content) {
  background: transparent !important;
}

.backup-list {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.backup-list > ion-item {
  --padding-end: 20px;
  --inner-padding-end: 0px;
}

.backup-list > ion-item::part(native) {
  padding: 0px !important;
}

.backup-list > ion-item > ion-label {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 19px !important;
}

.application-input {
  --border-color: #d7d7d7 !important;
  --border-style: solid !important;
  --border-width: 1px !important;
  --border-radius: 20px !important;
}

.application-connected > div > ion-button {
  --padding: 10px 14px;
  --gap: 8px;
  --background: #e4e4e4;
  --border-radius: 24px;
}

#modal-wrapper-wallet-connect {
  --height: fit-content;
  --border-radius: 8px;
  --width: 310px;
}

ion-modal#example-modal > .wrapper {
  width: 375px !important;
}

.setting-list > ion-item:last-child {
  --border-color: #f9f9f9;
}

.setting-list > ion-item {
  --padding-end: 20px !important;
}

.setting-list-desk > ion-item {
  --detail-icon-opacity: 0;
  --inner-padding-top: 5px;
  --inner-padding-bottom: 5px;
  --inner-padding-end: 20px;
  --inner-padding-start: 20px;
}

.setting-toolbar {
  --background: #f9f9f9;
}

.setting-content {
  --background: #f9f9f9;
  /* --padding-start: 0; */
  /* --padding-end: 0; */
}

.setting-content::part(scroll) {
  margin: 0px -16px;
}

.personal-wrapper {
  --padding-bottom: 20px;
}

.personal-wrapper > ion-label:last-child {
  margin-bottom: 16px;
}

.disconnect-modal {
  --width: 300px;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

.disconnect-modal-button-list > .disconnect-modal-cancel {
  --background: #e4e4e4;
  --border-radius: 24px;
}

.disconnect-modal-button-list > .disconnect-close {
  --background: #e4e4e4;
  --border-radius: 24px;
}

.disconnect-modal-button-list > .disconnect-modal-dis {
  --background: #214999;
  --border-radius: 24px;
}

.eye-icon {
  --background: none;
  --padding-end: 0.5em;
}

.eye-icons {
  --background: none;
}

.verification-failed-modal {
  --background: #ffffff !important;
}

.verification-failed-content {
  --padding-start: 25px;
  --padding-end: 25px;
}

.verification-toolbar {
  --border-color: transparent !important;
}

.profile {
  display: -webkit-box;
  display: -moz-box;
}

.white-bg {
  --background: #fff;
}

.modal-consent-datepicker::part(content) {
  width: 100%;
  max-width: 375px;
  margin: 0px auto;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

ion-content {
  --overflow: hidden;
}

.tx-sign::-webkit-scrollbar {
  display: none;
}

.permission-form-list .item {
  --background: #f9f9f9;
}

.consent-content {
  --padding-start: var(--ion-padding, 16px);
  --padding-end: var(--ion-padding, 16px);
  --padding-top: var(--ion-padding, 16px);
  --padding-bottom: var(--ion-padding, 16px);
  padding-left: var(--ion-padding, 16px);
  padding-right: var(--ion-padding, 16px);
  padding-top: var(--ion-padding, 16px);
  padding-bottom: var(--ion-padding, 16px);
}

ion-input.permission {
  --background: #e4e4e4;
  --color: #5d5d5d;
  --placeholder-color: #5d5d5d;
  --placeholder-opacity: 0.8;
  /* margin: 10px 0; */
  --padding-bottom: 10px;
  --padding-end: 10px;
  --padding-start: 10px !important;
  --padding-top: 10px;
  /* border-radius: 8px; */
}

ion-checkbox {
  --size: 26px;
  --background-checked: #214999;
}

ion-checkbox::part(container) {
  border-radius: 6px;
  border: 2px solid #214999;
}

#pending-transaction-content {
  --overflow: auto;
}

.badge {
  position: absolute;
  bottom: 6px;
  left: 0px;
  width: 18px;
  height: 18px;
  border: 1px solid #000;
}

.skeleton-item {
  animation: skeleton-loading 0.8s linear infinite alternate;
}

/* Account Page */

.btn-cash {
  --padding-end: 0px;
  --padding-start: 0px;
}

.copy-setting {
  --color: #214999 !important;
  --color: #214999 !important;
  --background: #e4e4e4;
  --background-hover: #e4e4e4;
}

.copy-setting > .button-native {
  font-weight: bold;
  cursor: pointer;
  --color: #214999 !important;
  --color: #214999 !important;
  --background: #e4e4e4;
  --background-hover: #e4e4e4;
}

.modal-qr {
  --max-width: 450px;
  --max-height: 460px;
}

/*  End Account Page */

@keyframes skeleton-loading {
  0% {
    background-color: #ccc;
  }

  100% {
    background-color: #eee;
  }
}

@media screen and (max-width: 640px) {
  .modal-transfer-done {
    --max-height: 560px;
  }

  .modal-confirm-done {
    --max-height: 560px;
  }
}

@media screen and (min-width: 1024px) {
  .modal-transfer-done {
    max-width: 100%;
    /* --max-width: 30%; */
    /* --max-height: 390px; */
    --max-width: 500px;
    --max-height: 415px;
  }

  .modal-confirm-done {
    max-width: 100%;
    /* --max-width: 305px; */
    /* --max-height: 390px; */
    --max-width: 500px;
    --max-height: 415px;
  }

  .modal-contact-bar {
    --max-width: 350px;
  }

  ion-modal#modal-gas-example {
    --backdrop-opacity: 0.75;
    --background: transparent;
  }
}

.removeBgColor {
  --background: none;
  --background-activated: none;
  --background-focused: none;
  --background-hover: none;
}

.privKey {
  --min-height: 150px;
}

.rounded-input {
  border-radius: 50px;
  border: 1px solid lightgray;
  width: 100%;
  padding: 0px 14px !important;
  font-size: 14px;
  line-height: 1.42857143;
}

#confirm-transfer-page {
  max-width: 375px !important;
}

.gas-view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}
.gas-view > .gas-view-label {
  font-weight: 600;
  padding: 5px;
}
.gas-view > .gas-view-value {
  background-color: #f9f9f9;
  border-radius: 15px;
  margin-bottom: 5px;
  padding: 5px;
}
.gas-view > #ion-icon-gas {
  width: 14px !important;
  position: absolute;
  max-width: 14px;
  margin-left: 30%;
  margin-bottom: 5px;
}
@media only screen and (max-width: 350px) {
  .gas-view > #ion-icon-gas {
    margin-left: 37%;
  }
}
@media only screen and (max-width: 295px) {
  .gas-view > #ion-icon-gas {
    margin-left: 40%;
  }
}

#pophover-content-transfer-gas-wrapper {
  position: fixed;
  bottom: 10px;
  z-index: 99;
  background: #f9f9f9;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 5px;
  font-size: 12px;
  width: 206px;
  right: 80px;
}

#pophover-content-transfer-gas-wrapper-desktop {
  position: fixed;
  z-index: 99;
  background: #f9f9f9;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 5px;
  font-size: 12px;
  width: 206px;
}

.pophover-content-fee {
  top: 30px;
  height: fit-content;
  right: 10vw;
  padding: 2px;
  width: 80%;
  z-index: 100;
  background: #f9f9f9;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: absolute;
}

.pophover-content-fee > p {
  font-size: 10px;
  line-height: 14px;
  color: #5d5d5d;
  padding: 5px;
}

.pophover-content-priority-fee {
  top: 80px;
  height: fit-content;
  right: 10vw;
  padding: 2px;
  width: 220px;
  z-index: 100;
  background: #f9f9f9;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: absolute;
}

.pophover-content-priority-fee > p {
  font-size: 10px;
  line-height: 14px;
  color: #5d5d5d;
  padding: 5px;
}

.gasview-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 5px;
}

.gasview-flex ion-icon {
  font-size: 14px;
  cursor: pointer;
}

@media screen and (min-width: 1024px) {
  ion-modal#modal-gas-example {
    --backdrop-opacity: 0.75;
    --background: transparent;
  }

  .pophover-content-fee {
    right: 0vw;
  }

  .pophover-content-priority-fee {
    right: 5vw;
  }

  #pophover-content-transfer-gas-wrapper {
    top: 54%;
    width: 320px;
    height: fit-content;
    /* left: 160px; */
  }

  #pophover-content-transfer-nft-wrapper {
    top: 382px;
    width: 320px;
    height: fit-content;
    left: 160px;
  }
}

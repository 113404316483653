.scanner-modal > ion-modal {
  --background: #3d3d3d !important;
}
.scanner-header {
  --border-color: transparent !important;
  --background: #3d3d3d !important;
}
.scanner-content {
  --background: #3d3d3d;
}
ol.setting-content {
  --background: #f5f5f5;
  --padding-start: 0;
  --padding-end: 0;
}

.setting-toolbar {
  --background: #f9f9f9;
}

.inquiry {
  --border-color: #dddcdf !important;
}

.customize-qr-reader {
  width: 100%;
}

.find-code-wrapper {
  position: fixed;
  width: 100%;
  z-index: 100;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.find-code {
  position: relative;
  text-align: center;
  justify-content: center;
  display: flex;
  margin: 0 auto;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
}

#scanner-content {
  width: 100% !important;
  height: 100vh !important;
}

#white-content-wrapper {
  width: 250px;
  height: 250px;
  background: none !important;
  position: fixed;
  z-index: 100;
  background-color: none;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#scanner-content > section > section {
  position: fixed !important;
  overflow: hidden !important;
  border: none !important;
  height: 100vh;
}
#scanner-content > section > section > div {
  width: 0px !important;
  height: 0px !important;
  border: none !important;
  background-color: none !important;
  box-shadow: none !important;
}

.scanner-left-border {
  position: absolute;
  border-left: 9px solid #ffff;
  border-top: 9px solid #ffff;
  border-top-left-radius: 50px;
  border-top-right-radius: 2px;
  z-index: 100;
  width: 90px !important;
  height: 90px !important;
  left: 10%;
  top: 23%;
  transform: translate(-50%, -50%);
}
.scanner-right-border {
  position: absolute;
  border-right: 9px solid #ffff;
  border-top: 9px solid #ffff;
  border-top-right-radius: 50px;
  border-top-left-radius: 2px;
  z-index: 100;
  width: 90px !important;
  height: 90px !important;
  right: -23%;
  top: 23%;
  transform: translate(-50%, -50%);
}
.scanner-left-bottom-border {
  position: absolute;
  border-left: 9px solid #ffff;
  border-bottom: 9px solid #ffff;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 2px;
  z-index: 100;
  width: 90px !important;
  height: 90px !important;
  left: 10%;
  top: 85%;
  transform: translate(-50%, -50%);
}
.scanner-right-bottom-border {
  position: absolute;
  border-right: 9px solid #ffff;
  border-bottom: 9px solid #ffff;
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 2px;
  z-index: 100;
  width: 90px !important;
  height: 90px !important;
  right: -23%;
  top: 85%;
  transform: translate(-50%, -50%);
}

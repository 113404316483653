.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.content > .section-top {
  text-align: center;
  margin-top: 20%;
  font-weight: 400;
}

.content > .section-top > h5 {
  font-size: 16px;
}

.content > .section-middle {
  text-align: center;
  margin-top: 50%;
  font-weight: 400;
  display: flex;
  flex-direction: column;
}
.content > .section-middle > h5 {
  font-size: 16px;
  margin-bottom: 40px;
}

.content > .section-middle > ion-button > ion-icon {
  background: transparent;
}

.content > .section-bottom {
  text-align: center;
  margin-top: 50%;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 16px;
}
.content > .section-bottom > h5 {
  font-size: 16px;
  margin-bottom: 40px;
}
.content > .section-bottom > h5 > span {
  /* font-weight: bold; */
  text-decoration: underline;
}

ion-select {
  max-width: 100%;
  min-width: 100%;
  margin: 10px 0;
}

button.close-popup-button {
  position: absolute !important;
  background: none !important;
  z-index: 9999999999 !important;
}

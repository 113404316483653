/* --- Still in the period of repair and testing, please do not change or delete this commented code --- */
/* .tooltip-bc:hover .bottom {
  visibility: visible;
  opacity: 1;
} */

.arrow-icon::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  right: 2%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: #5d5d5d;
  border: 1px solid #5d5d5d;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
}

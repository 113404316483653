.bottom-left {
  position: fixed;
  bottom: 0.3em;
  left: 0.3em;
  z-index: 999999 !important;
  display: block;
}

.bottom-right {
  position: fixed;
  bottom: 0.3em;
  right: 0.3em;
  z-index: 1234 !important;
}

.top-left {
  position: fixed;
  top: 0.3em;
  left: 0.3em;
  z-index: 1234 !important;
}

.top-right {
  position: fixed;
  top: 0.3em;
  right: 0.3em;
  z-index: 1234 !important;
}

.widget-bottom > .open-widget-button {
  --background: #fff;
}

ion-fab-button::part(native) {
  box-shadow: none !important;
}

.ion-activated {
  background-color: none;
  box-shadow: none !important;
  --transform: scale(1.1);
  --transition: 0.2s transform ease-out;
}

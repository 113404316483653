ion-content::part(scroll)::-webkit-scrollbar {
  display: none;
}

ion-select::part(icon) {
  content: url("../../assets/icons/chevron-down.svg");
  width: 13px;
  opacity: 1;
}

.tooltip-set {
  display: inline-block;
  position: relative;
  text-align: left;
  top: 10px;
}

.tooltip-set .bottom {
  height: auto;
  top: 30px;
  left: 0%;
  transform: translate(-50%, 0);
  color: #ffffff;
  padding: 8px;
  background-color: #5d5d5d;
  font-weight: normal;
  font-size: 12px;
  border-radius: 10px;
  position: absolute;
  font-family: "Roboto";
  z-index: 999999999;
  box-sizing: border-box;
  border: 1px solid #5d5d5d;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  visibility: visible;
  opacity: 1;
  transition: opacity 0.8s;
}

.tooltip-set:hover .bottom {
  visibility: visible;
  opacity: 1;
}

.tooltip-set .bottom i {
  position: absolute;
  bottom: 100%;
  left: 5%;
  margin-right: -12px;
  width: 24px;
  height: 12px;
  overflow: hidden;
}

.tooltip-set .bottom i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  right: 2%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: #5d5d5d;
  border: 1px solid #5d5d5d;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
}

.tooltip-set-qr {
  display: inline-block;
  position: relative;
  text-align: left;
}

.tooltip-set-qr .bottom {
  max-width: 300px;
  transform: translate(-50%, 0);
  color: #ffffff;

  background-color: #5d5d5d;
  font-weight: normal;
  font-size: 12px;
  border-radius: 10px;
  position: absolute;
  font-family: "Roboto";
  z-index: 99999999;
  box-sizing: border-box;
  border: 1px solid #5d5d5d;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  visibility: visible;
  opacity: 1;
  transition: opacity 0.8s;
}

.tooltip-set-qr:hover .bottom {
  visibility: visible;
  opacity: 1;
}

.tooltip-set-qr .bottom i {
  position: absolute;
  bottom: 100%;
  right: 10%;
  margin-right: -12px;
  width: 24px;
  height: 12px;
  overflow: hidden;
}

.tooltip-set-qr .bottom i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  right: 2%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: #5d5d5d;
  border: 1px solid #5d5d5d;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
}

.modal-mobile {
  padding: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  --max-height: 420px;
  border-radius: 10px;
  --border-radius: 10px;
}
.wrapper-mobile {
  padding: 1rem;
}

.network-alert .alert-wrapper {
  min-width: 375px;
}

.network-alert .alert-wrapper .alert-button-group.sc-ion-alert-ios .alert-button-role-cancel {
  display: none;
}

.network-alert .alert-wrapper .alert-radio-group.sc-ion-alert-ios,
.alert-checkbox-group.sc-ion-alert-ios {
  border: 0;
}

.network-alert .alert-wrapper .alert-button-group.sc-ion-alert-ios {
  width: 90%;
  margin: 20px;
  background: #214999;
  border-radius: 100px;
  color: white;
  border: 0;
}
.network-alert .alert-wrapper .alert-button.sc-ion-alert-ios:last-child {
  border: 0;
}

.network-alert .alert-wrapper .alert-button-group.sc-ion-alert-ios span {
  color: white;
}
.network-alert .alert-wrapper .alert-message.sc-ion-alert-ios:empty {
  display: none;
}

.see-details {
  --max-width: 300px;
  --max-height: 145px;
}

.modal-open-explorer-pc {
  --max-width: 300px;
  --max-height: 145px;
}

.modal-open-explorer-pc > #ion-react-wrapper {
  padding: 15px;
}

.modal-open-explorer-pc > #ion-react-wrapper > ion-button:nth-child(1) {
  margin-bottom: 15px;
  --border-radius: 100px;
}
.modal-open-explorer-pc > #ion-react-wrapper > ion-button:nth-child(2) {
  --color: #214999 !important;
  --background: #e4e4e4;
  --background-hover: #e4e4e4;
  --border-radius: 100px;
}

.modal-open-explorer {
  --background: none;
  --bottom: 10px !important;
}

.modal-open-explorer > #ion-react-wrapper {
  height: 160px !important;
  bottom: 1px !important;
  width: 100% !important;
  padding: 0 5px !important;
  position: fixed;
}
#button-explorer {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.38px;
  --background: #ffffff;
  --background-hover: #e4e4e4;
}
#button-explorer:nth-child(1) {
  --color: #000000 !important;
  margin-bottom: 13px;
  height: 60px;
  cursor: pointer;
}
#button-explorer:nth-child(2) {
  --color: #ff5353 !important;
  height: 60px;
  cursor: pointer;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.container > ion-button {
  width: 268px;
  border-radius: 100px;
  --border-radius: 100px;
}

.cancel-setting {
  font-weight: bold;
  cursor: pointer;
  --color: #214999 !important;
  --background: #e4e4e4;
  --background-hover: #e4e4e4;
}
@media screen and (min-width: 1024px) {
  .card-account {
    display: flex;
    margin-top: 1%;
    justify-content: center;
  }
  .tooltip-set-qr {
    display: inline-block;
    position: relative;
    text-align: left;
  }
}

.account-address {
  width: 180px;
  font-size: 14px;
  margin: 0 auto;
  color: #5d5d5d;
  margin-bottom: 22px;
}

.cog {
  height: 1.8em;
  --padding-start: 5px;
  --padding-end: 5px;
}

.sc-ion-buttons-md-s .button-has-icon-only.button-clear {
  height: 36px;
  background: #214999;
  border-radius: 50%;
  width: 36px;
  margin-right: 1%;
  --padding-top: 0;
  --padding-end: 10px;
  --padding-bottom: 0;
  --padding-start: 10px;
}
.sc-ion-buttons-md-s ion-icon[slot="icon-only"] {
  font-size: 3em;
}

.sectionButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 2rem;
}

.sectionButtons > ion-button {
  border-radius: 10px;
  min-width: 85px;
}

.title {
  font-size: 24px;
  margin: 0 5rem;
  text-align: center;
}

.collectible {
  border: none !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #e4e4e4 !important;
}

.all-home {
  width: fit-content;
  height: 32px;
  padding: 0 0.5px;
  color: #214999 !important;
  --padding-end: 8px;
  --padding-start: 8px;
  font-weight: bold;
  font-size: 12px;
  --background: #e4e4e4;
}

.all-home:hover {
  --background: #e4e4e4;
  --background-activated: #e4e4e4;
  --background-focused: #e4e4e4;
  --background-hover: #e4e4e4;
}

#root
  > ion-app
  > ion-router-outlet
  > div
  > ion-tabs
  > div
  > ion-router-outlet
  > div
  > ion-content
  > ion-list
  > ion-item:nth-child(1)
  > ion-avatar
  > ion-img:nth-child(1)
  > img {
  padding: 10px;
}
#token-network-big-img {
  width: 100px !important;
}

.accordion-nft {
  width: 100%;
  margin: 0;
  padding-top: 5px;
}

.nft-box {
  border-radius: 16px;
  font-size: 10px;
  position: relative;
}

.nft-img {
  height: 343px;
  width: auto;
  border-radius: 15px;
}

.modal-nft {
  --max-width: 380px;
  --max-height: 520px;
  padding: 1%;
}

.tooltip-copy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8px;
  gap: 6px;
  color: white;
  font-size: 12px;
  width: 128px;
  height: auto;
  margin: 0 auto;
  text-align: center;
  /* Neutral/Grey 80 */

  background: #5d5d5d;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 0;
}

.arrow {
  background: #5d5d5d;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-bottom: 5px solid black;
}
.tooltip {
  display: inline-block;
  position: relative;
  text-align: left;
}

.tooltip .bottom {
  min-width: 128px;
  max-width: 128px;
  height: auto;
  top: 5px;
  left: 55%;
  transform: translate(-50%, 0);
  color: #ffffff;
  padding: 8px;
  background-color: #5d5d5d;
  font-weight: normal;
  font-size: 12px;
  border-radius: 10px;
  position: absolute;
  font-family: "Roboto";
  z-index: 99999999;
  box-sizing: border-box;
  border: 1px solid #5d5d5d;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  visibility: visible;
  opacity: 1;
  transition: opacity 0.8s;
}

.tooltip:hover .bottom {
  visibility: visible;
  opacity: 1;
}

.tooltip .bottom i {
  position: absolute;
  bottom: 100%;
  right: 5%;
  margin-left: -12px;
  width: 24px;
  height: 12px;
  overflow: hidden;
}

.tooltip .bottom i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  right: 2%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: #5d5d5d;
  border: 1px solid #5d5d5d;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
}

.flexBanner {
  display: flex;
  justify-content: center;
  width: 100vw;
  flex-direction: column;
  text-align: right;
  font-size: 8px;
  margin: 10px -17px;
  cursor: pointer;
}

.flexBanner > .contentBanner {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.flexBanner > .content > img {
  max-width: 484px;
}

.flexBanner > p {
  margin: 0;
  margin-right: 1px;
}
.mr-1half {
  margin-right: 1.2px;
}

.fixedbottom {
  position: fixed;
  bottom: 2%;
}
@media screen and (min-width: 1024px) {
  .flexBanner {
    width: 100%;
    margin: 0 auto;
    align-items: center;
  }

  .card-home {
    /* margin: 0 10px;
    width: 100%; */
    max-height: 500px;
  }

  .mh-60 {
    max-height: 60vh;
  }

  .mh-80vh {
    max-height: 80vh;
  }

  .adjust-right {
    width: 96%;
  }

  .card-content-ios {
    width: 100%;
  }
}

.progressBar {
  height: 10px;
  border-radius: 30px;
  width: 320px;
}

.content-login {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 500px;
  margin: 0 auto;
}

.content-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content-login > .section-top-login {
  text-align: center;
  margin-top: 20%;
  font-weight: 400;
}

.content-login > .section-top-login > h5 {
  font-size: 16px;
}

.content-login > .section-middle-auth {
  text-align: center;
  margin: 30% 0;
  font-weight: 400;
  min-width: 80%;
  display: flex;
  flex-direction: column;
}
.content-login > .section-middle-auth > h5 {
  font-size: 16px;
  margin-bottom: 5%;
  font-weight: bold;
}

.content-login > .section-middle-auth > ion-button > ion-icon {
  background: transparent;
}

.content-login > .section-bottom-auth {
  text-align: center;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 16px;
}
.content-login > .section-bottom-auth > h5 {
  font-size: 16px;
  margin-bottom: 40px;
}
.content-login > .section-bottom-auth > h5 > span {
  text-decoration: underline;
}

.content-login > .section-bottom-auth > ion-button {
  font-size: 16px;
  margin: 0 auto;
}

/* .notice {
  border: 1px solid #ff0000;
  text-align: center;
  color: #ff0000;
  line-height: 1.5rem;
  font-size: 13px;
  font-weight: bold;
}
.orange {
  color: #ff0000;
  text-align: center;
  font-size: 14px;
  margin-top: -20px;
  margin-bottom: 45px;
} */
ion-select {
  max-width: 100%;
  min-width: 100%;
  margin: 10px 0;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.my-custom-class {
  --background: white;
  --spinner-color: #214999;
}

.no-overflow {
  overflow: hidden;
}

@media screen and (max-width: 376px) {
  .content-login > .section-top-login {
    text-align: center;
    margin-top: 5%;
    font-weight: 400;
  }

  .content-login > .section-middle-auth {
    margin: 10% 0;
  }

  /* .notice {
    font-size: 11px;
  } */
}

.bw-0 {
  --border-width: 0 0 0 0 !important;
}

.spinner {
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.pincode-input-container input {
  border-color: #d7d7d7;
  border-width: 1px;
  border-radius: 10px;
}

.pincode-input-container-backup input {
  text-transform: lowercase;
  border-color: #d7d7d7;
  border-width: 1px;
  border-radius: 10px;
}

/* Apply styles on focus (when clicked or tabbed into) */

/* .pincode-input-container > .pincode-input-text:focus, :hover, :focus-visible {
  border-color: #4d4082;
  border-width: 2px;
} */
/* Apply styles when the container is visited or hovered */
.pincode-input-container:hover input,
.pincode-input-container:visited input {
  border-color: #4d4082;
  border-width: 2px; /* Change border color on hover or visit */
}

/* Apply styles on focus (when clicked or tabbed into) */
.pincode-input-container input:focus {
  outline: none; /* Remove default focus outline */
  border-color: #4d4082; /* Change border color on focus */
  border-width: 2px;
}

.pin-input {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 1em;
  padding: 30px 0px;
  font-size: 24px;
  font-weight: "500";
}

.pin-input-2 {
  width: 100%;
  justify-content: space-between;
  /* flex-direction: column; */
  padding: 30px 8px;
  font-size: 24px;
  font-weight: 500;
}

.pin-input__field {
  border: 1px solid #d7d7d7;
  /* flex-direction: row; */
  border-radius: 10px;
  text-align: center;
}

.pin-input:hover .pin-input__field,
.pin-input:visited .pin-input__field {
  border-color: #4d4082;
  border-width: 2px;
}

.pin-input:focus .pin-input__field {
  border-color: #4d4082;
  border-width: 2px;
}

.pincode-input .pin-input__field:focus {
  outline: none;
  border-color: #4d4082;
  border-width: 2px;
}

.pin-input__field:focus,
.pin-input__field:focus-visible,
.pin-input__field:active,
.pin-input__field:hover {
  outline: none;
  border-color: #4d4082;
  border-width: 2px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 1024px) {
  .content-login > .section-middle-auth {
    margin: 5% 0;
    min-width: 30%;
  }
  .content-login > .section-top-login {
    margin-top: 5%;
  }

  .content-login {
    max-width: 100%;
  }

  /* .notice {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    margin-bottom: 10px;
  } */
}

.pincode-input-text {
  font-family: Poppins, sans-serif;
}
.font-poppins {
  font-family: Poppins, sans-serif;
}
.headline {
  font-size: 1.75rem !important;
  font-weight: 600;
  line-height: 2.435rem;
  letter-spacing: 0.0073529412em !important;
  font-family: Inter, sans-serif !important;
  color: #25282b;
}
.headline-2 {
  font-size: 1.5rem !important;
  font-weight: 600;
  line-height: 2.435rem;
  font-family: Poppins, sans-serif !important;
  color: #25282b;
}
.bconfirm:disabled {
  background: #f5f5f5;
  color: #bbbbbb;
  cursor: no-drop;
  font-weight: 500;
}

.bconfirm {
  background: #214999;
  color: white;
  font-weight: 500;
}

.bconfirm-2:disabled {
  background: #f9f9f9;
  color: #bbbbbb;
  cursor: no-drop;
  font-weight: 500;
}

.bconfirm-2 {
  background: #214999;
  color: white;
  font-weight: 500;
}

.btn-backup {
  font-size: 1rem;
  padding: 14px 26px;
}

.shadow {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}

.shadow-2 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

/* .pincode-input-container-backup:hover input,
.pincode-input-container-backup:visited input {
  border-color: #fb4a61;

  border-width: 1px; 
} */

/* Apply styles on focus (when clicked or tabbed into) */
/* .pincode-input-container-backup input:focus {
  outline: none;
  border-color: #fb4a61; 
  border-width: 1px;
} */

.font-28 {
  font-size: 32px;
  font-weight: 600;
}

.slide-out {
  transform: translateY(0);
  animation: slideOut 0.5s ease forwards;
}

@keyframes slideOut {
  to {
    transform: translateY(100%);
  }
}

.slide-in {
  transform: translateY(100%);
  animation: slideIn 0.5s ease forwards;
}

@keyframes slideIn {
  to {
    transform: translateY(0);
  }
}
